import React from 'react'
import Index from '../components/applynow'
import { Helmet } from 'react-helmet'

const applynow = () => {
    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Auto Genius - Apply to Join Our Marketing Community</title>
            <meta
              name="description"
              content="Apply to become a member of Auto Genius, the community of automotive marketers."
            />
          </Helmet>
          <div>
        
            <Index />
        </div></>
    )
}

export default applynow
