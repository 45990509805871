import React from 'react'
import { ReactTypeformEmbed } from 'react-typeform-embed';
const Index = () => {
    return (
        <div className="h-full formwrapper">
            <ReactTypeformEmbed url="https://form.typeform.com/to/ODzKRKzf" />
        </div>
    )
}

export default Index
